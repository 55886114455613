import { marketplaceApi as api } from 'services/base-axios'
import { getBlockscoutService } from 'services/blockscout'

import configs from 'configs'

import { ProductType } from 'constant/marketplace'
import { ChainID } from 'constant'

import type { UserNFTData } from 'store/walletNFT.reducer'
import type { IUserNftResponse } from 'types/user-nft.type'

const EVM_CONTROLLER = '/evm/nft'
const RONIN_CONTROLLER = '/ronin/nft'

class UserNft {
  private getMoralisNft = async (userNftDto: UserNftDto) => {
    return api
      .get(EVM_CONTROLLER, {
        params: {
          ...userNftDto,
        },
      })
      .then((data) => data.data)
  }

  private getBlockscoutNft = async (
    chainId: ChainID,
    userNftDto: UserNftDto,
  ) => {
    const blockscoutService = getBlockscoutService(chainId)

    return blockscoutService
      .getAddressTokens(configs.walletEVM.adminAddress, 'ERC-1155', {
        params: userNftDto?.cursor,
      })
      .then((data) => {
        const listNft = data?.data?.items

        const result: UserNFTData[] = listNft?.map((nftDetail) => {
          const tokenInfoMeta = nftDetail.token_instance?.metadata

          return {
            amount: nftDetail.value,
            attributes: tokenInfoMeta?.attributes,
            collectionName: tokenInfoMeta?.collectionName,
            contractType: nftDetail.token.type,
            description: tokenInfoMeta?.description ?? '',
            image: tokenInfoMeta?.image ?? '',
            name: tokenInfoMeta?.name ?? '',
            ownerOf: configs.walletEVM.adminAddress,
            symbol: nftDetail.token.symbol,
            tokenAddress: nftDetail.token.address,
            tokenId: nftDetail?.token_id
              ? parseInt(nftDetail.token_id)
              : undefined,
          } as UserNFTData
        })

        return {
          result,
          cursor: data.data?.next_page_params,
          chainId,
        }
      })
  }

  private getRoninNft = async (userNftDto: UserNftDto) => {
    return api
      .get(RONIN_CONTROLLER, {
        params: {
          ...userNftDto,
        },
      })
      .then((data) => data.data)
  }

  async getUserNft(userNftDto: UserNftDto): Promise<IUserNftResponse> {
    const { chainId } = userNftDto

    switch (chainId) {
      case ChainID.Ronin:
        return this.getRoninNft(userNftDto)
      case ChainID.A8:
      case ChainID.Etherlink:
        return this.getBlockscoutNft(chainId, userNftDto)
      default:
        return this.getMoralisNft(userNftDto)
    }
  }
}

export type UserNftDto = {
  userAddress?: string
  chainId: ChainID
  collectionAddress?: string
  limit?: number
  cursor?: string
  productType?: ProductType
}

export type GetCollectionDto = {
  address: string
  chainId?: string
}

export const UserNftService = new UserNft()
