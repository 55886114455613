import { Configuration, DefaultApi } from 'blockscout-cli'

import configs from 'configs'
import { SupportChain } from 'configs/blockscout.config'

import { ChainID } from 'constant'

const blockscoutInstances: Record<ChainID, DefaultApi> = {} as Record<
  ChainID,
  DefaultApi
>

const createBlockscoutService = (chainId: SupportChain): DefaultApi => {
  if (!configs.blockscout[chainId]) {
    throw new Error(
      `Blockscout configuration not found for chainId: ${chainId}`,
    )
  }

  const configApi = new Configuration({
    basePath: configs.blockscout[chainId].basePath,
  })

  return new DefaultApi(configApi)
}

const initializeBlockscoutServices = () => {
  Object.keys(configs.blockscout).forEach((chainId) => {
    blockscoutInstances[chainId as SupportChain] = createBlockscoutService(
      chainId as SupportChain,
    )
  })
}
initializeBlockscoutServices()

export const getBlockscoutService = (chainId: ChainID): DefaultApi => {
  const service = blockscoutInstances[chainId]
  if (!service) {
    throw new Error(`BlockScoutService not initialized for chainId: ${chainId}`)
  }
  return service
}
