import { ChainID } from 'constant'
import { Env } from './env'

/**
 * Constructor
 */

export type SupportChain = ChainID.A8 | ChainID.Etherlink

type BlockscoutConfig = {
  basePath?: string
}

type Config = Record<SupportChain, BlockscoutConfig>

const configs: Record<Env, Config> = {
  /**
   * Development configurations
   */
  development: {
    [ChainID.A8]: {
      basePath: 'https://scanv2-testnet.ancient8.gg/api/v2',
    },
    [ChainID.Etherlink]: {
      basePath: 'https://testnet.explorer.etherlink.com/api/v2',
    },
  },

  /**
   * Staging configurations
   */
  staging: {
    [ChainID.A8]: {
      basePath: 'https://scanv2-testnet.ancient8.gg/api/v2',
    },
    [ChainID.Etherlink]: {
      basePath: 'https://testnet.explorer.etherlink.com/api/v2',
    },
  },

  /**
   * Production configurations
   */
  production: {
    [ChainID.A8]: {
      basePath: 'https://scan.ancient8.gg/api/v2',
    },
    [ChainID.Etherlink]: {
      basePath: 'https://explorer.etherlink.com/api/v2',
    },
  },
}

/**
 * Module exports
 */
export default configs
