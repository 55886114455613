import { useCallback } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'

import { UserNftDto, UserNftService } from 'services/marketplace/evm/userNft'

import { AppState } from 'store'

export const useUserNFTs = () => {
  const userNFTs = useSelector((state: AppState) => state.nft.userNFTs)
  return userNFTs
}

export const useUserNFTDetails = (key: string) => {
  const userNFT = useSelector((state: AppState) => state.nft.userNFTs[key])
  return userNFT
}

export const useCurrentCursor = () => {
  return useSelector((state: AppState) => state.nft.cursor)
}

export const useUserNFTCheckActive = () => {
  const products = useSelector((state: AppState) => state.products)

  return useCallback(
    ({ web3ItemId }: { web3ItemId: string }) => {
      return (
        Object.values(products).find(
          (item) => item.web3ItemId === web3ItemId,
        ) === undefined
      )
    },
    [products],
  )
}

export const useQueryUserNFTs = (params: UserNftDto, enabled?: boolean) => {
  return useQuery({
    queryKey: ['GET_USER_NFTS', params],
    queryFn: () => UserNftService.getUserNft(params),
    enabled,
  })
}
